<script setup lang="ts">
import { prefToAlphabet } from "~/utils/sekou/area";

// const { attr } = useRoute().query;

const props = defineProps<{
  prefecture: string;
  city: string;
  count: number;
}>();

// その他の場合は、URLをotherにする
function linkCity(city: string) {
  if ("その他" == city) return "other";
  return city;
}

const to = computed(() => {
  // 準備が出来れば使用する
  // if (attr) {
  //   const param: { attr: string; pref: string; city?: string } = {
  //     attr: attr.toString(),
  //     pref: props.prefecture,
  //   };

  //   if (props.city) {
  //     param.city = props.city;
  //   }

  //   return `/sekou/search.php?${new URLSearchParams(param).toString()}`;
  // }

  return `/sekou/${prefToAlphabet(props.prefecture)}${
    props.city ? "_" + linkCity(props.city) : ""
  }`;
});
</script>

<template>
  <NuxtLink
    :to="to"
    class="flex justify-between p-2 hover:bg-gray-100"
    active-class="is-active"
  >
    <span>{{ city || prefecture }}</span>

    {{ count }}件
  </NuxtLink>
</template>
